<template>
  <section id="about" class="about">
    <Title class="about-title" text="SOBRE" />
    <Carousel />
    <div class="intro">
      <img src="../../assets/images/quote-left.png" alt="left-quote" />
      <span>
        Sou de São Paulo, tenho {{ ageCalc }} anos e sou uma pessoa apaixonada por
        tecnologia! Sou formado Engenheiro Eletricista e atualmente estou cursando
        a Pós-Graduação na FGV em Administração de Empresas.
        Tenho skills desenvolvidas na área da comunicação devido a atuação na
        área comercial de uma multinacional. <br /><br />Além do foco no
        cliente, meu papel é garantir que os interesses dos principais
        stakeholders estejam alinhados com o produto. Possuo experiência em
        mapeamento e gerenciamento de projetos, processos, desenvolvimento de
        software, UX/UI e metodologias ágeis.
      </span>
      <img src="../../assets/images/quote-right.png" alt="right-quote" />
    </div>
  </section>
</template>

<script>
import Title from '../atoms/Title';
import Carousel from '../molecules/Carousel';

export default {
  name: 'About',
  components: {
    Title,
    Carousel,
  },
  data() {
    return {
      age: 0
    }
  },
  computed: {
    ageCalc () {
      let age = 0;
      const dateOne = new Date("11/14/1998");
      const dateNow = Date.now()
      const difference = dateNow - dateOne.getTime()
      age = difference / (1000 * 60 * 60 * 24) / 360;
      return Math.trunc(age);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.about {
  width: 100%;
  margin-bottom: 100px;

  .about-title {
    margin-top: 70px;
  }

  .intro {
    margin: 50px 0;
    width: 100%;
    font-size: 1.3rem;
    color: $white;

    span {
      font-weight: 200;
    }
  }
}

@include for-size(phone-only) {
  .about {
    .intro {
      margin: 25px 0;
      width: 100%;
      color: $white;
      text-align: center;

      span {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
