<template>
  <div class="accomplishment">
    <img :src="require(`../../assets/images/${icon}`)" alt="" class="icon" />
    <span>{{ date }}</span>
    <p class="title_badge">{{ title }}</p>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'Accomplishment',
  props: ['title', 'icon', 'description', 'date'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.accomplishment {
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: $darker-grey;
  padding: 30px 30px;
  border-radius: 3px;
  text-align: center;
  align-items: center;

  .title_badge {
    font-size: 1.4rem;
    font-weight: 500;
    font-style: 'Montserrat';
    color: $primary;
  }

  p {
    font-family: 'LATO';
    font-size: 1.1rem;
    font-weight: 300;
    color: $secondary;
  }

  span {
    color: $secondary;
    margin: 10px 0;
  }

  img {
    width: 75px;
  }
}

@include for-size(tablet-portrait-up) {
  .accomplishment {
    align-items: center;
    margin: 0 auto;
  }
}

@include for-size(phone-only) {
  .accomplishment {
    margin: 10px 0;
    width: 80%;
    margin: 0 auto;
  }
}
</style>
