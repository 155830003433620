<template>
  <section id="contact" class="contact-form">
    <Title class="contact-title" text="CONTATO" />
    <div class="contact-group">
      <v-form
        name="contact"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submitForm"
      >
        <p style="display:none" class="hidden">
          <label> Don't fill this : <input name="bot-field"/></label>
        </p>
        <v-text-field
          name="name"
          v-model="fields.name"
          :counter="15"
          :rules="nameRules"
          label="Name"
          outlined
          dark
          required
          color="#e5c938"
        ></v-text-field>

        <v-text-field
          name="email"
          v-model="fields.email"
          :rules="emailRules"
          label="E-mail"
          required
          outlined
          color="#e5c938"
          dark
        ></v-text-field>

        <v-text-field
          name="message"
          v-model="fields.message"
          :rules="messageRules"
          :counter="300"
          height="150px"
          label="Mensagem"
          required
          outlined
          color="#e5c938"
          dark
        ></v-text-field>
        <div class="error-box" v-if="this.statusForm">
          <span class="error-message">{{ statusForm }}</span>
        </div>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click.prevent="handleSubmit"
        >
          Enviar
        </v-btn>
      </v-form>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';
import Title from '../atoms/Title';

export default {
  name: 'Contact',
  components: { Title },
  data: () => ({
    valid: true,
    statusForm: '',
    fields: {
      name: '',
      email: '',
      message: '',
    },
    nameRules: [
      (v) => !!v || 'Nome é obrigatório',
      (v) => (v && v.length <= 15) || 'Nome deve ter menos de 15 caractéres',
    ],
    emailRules: [
      (v) => !!v || 'E-mail é Obrigatório',
      (v) => /.+@.+\..+/.test(v) || 'O E-mail deve ser válido',
    ],
    messageRules: [
      (v) => !!v || 'Mensagem é obrigatória',
      (v) => (v && v.length <= 300) || 'A mensagem deve ter menos de 300 caractéres!',
    ],
  }),

  methods: {
    submitForm(event) {
      event.target.classList.add('was-validated');
      // submit form
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&');
    },
    handleSubmit() {
      this.statusForm = '';
      fetch('/', {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: this.encode({
          'form-name': 'contact',
          ...this.fields,
        }),
      })
        .then(() => {
          this.statusForm = 'Seu formulário foi enviado com sucesso!';
          this.fields = '';
          console.log('successfully sent');
        })
        .catch(
          (err) =>
            (this.statusForm = `Ops, seu formulário não foi enviado com sucesso! Erro:${err}`)
        );
      // console.error(err));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.contact-form {
  width: 100%;
  background-color: $background;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  width: 80%;
  max-width: 1450px;
  padding: 0;

  .contact-title {
    margin-top: 70px;
  }

  .contact-group {
    width: 100%;
    margin: 15px auto;

    .error-box {
      margin-bottom: 15px;
      .error-message {
        color: $primary;
      }
    }
  }
}
@include for-size(phone-only) {
}
</style>
