<template>
  <section id="accomplishments" class="accomplishments">
    <Title class="accomplishment-title" text="CONQUISTAS" />
    <div class="accomplishments-body">
      <Accomplishment
        title="Pós-Graduação em Administração de Empresas"
        description="Fundação Getúlio Vargas"
        date="05/2022 ~ 05/2023"
        icon="icon-certificate.png"
      />
      <Accomplishment
        title="Formado em Engenharia Elétrica"
        description="Universidade Anhembi Morumbi"
        date="06/2015 ~ 12/2021"
        icon="icon-graduation-cap.png"
      />
      <Accomplishment
        title="Cadete na 42 São Paulo"

        description="Selecionado o processo seletivo da 42"
        date="01/2021"
        icon="icon-binary.png"
      />
      <Accomplishment
        title="Web FullStack Developer"
        description="Santander Coders - Digital House"
        date="07/2020"
        icon="icon-web-programming 1.png"
      />
      <Accomplishment
        title="Imersão de Design Thinking"
        description="Echos | Innovation lab"
        date="07/2019"
        icon="design-thinking.png"
      />
      <Accomplishment
        title="Técnico em Mecatrônica"
        description="Faculdade de Tecnologia Senai Anchieta"
        date="06/2015"
        icon="icon-robotic-arm.png"
      />
      <Accomplishment
        title="Participante na XXIIOBA"
        description="XII Olimpíada de Astronomia e Astrontáutica"
        date="08/2009"
        icon="icon-medal.png"
      />
    </div>
  </section>
</template>

<script>
import Title from '../atoms/Title';
import Accomplishment from '../molecules/Accomplishment';

export default {
  name: 'Accomplishments',
  components: {
    Title,
    Accomplishment,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.accomplishments {
  width: 100%;
  margin-bottom: 100px;

  .accomplishment-title {
    margin-top: 70px;
  }

  .accomplishments-body {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 2rem;
    align-items: center;
  }
}

@include for-size(phone-only) {
  .accomplishments {
    display: flex;
    flex-direction: column;
    // width: 300px;
    align-items: center;
    text-align: center;
    justify-content: center;

    .accomplishment-title {
      align-self: start;
    }
  }
}
</style>
