<template>
  <header class="navbar">
    <div class="navbar-container">
      <div class="icon">
        <a href="#home"><img src="../../assets/images/logo_text.png" alt="logo" /></a>
      </div>
      <div class="options">
        <a class="option" href="#about">SOBRE</a>
        <a class="option" href="#accomplishments">CONQUISTAS</a>
        <a class="option" href="#career">CARREIRA</a>
        <a class="option" href="#contact"><span>CONTATO</span></a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/global';

.navbar {
  width: 100%;
  height: fit-content;
  background-color: $navbar;
  z-index: 20;
  position: fixed;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    max-width: 1450px;
    padding: 0;

    .icon {
      display: flex;
      align-items: center;
    }

    .options {
      font-family: 'Lato';
      font-weight: 300;
      font-size: 18px;
      display: flex;
      align-items: center;
      z-index: 20;

      .option {
        display: flex;
        text-decoration: none;
        color: $white;
        margin-right: 15px;
        width: 150px;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        height: 100%;
      }

      .option:last-child {
        margin: 0;
        border-bottom: 1px solid $primary;
        font-weight: bold;

        span {
          margin-top: 1px;
        }
      }

      .option:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@include for-size(phone-only) {
  .navbar {
    display: none;
  }
}
</style>
