<template>
  <div class="title">
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: ['text'],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global";
.title {
  font-size: 1.6rem;
  color: $white;
  font-family: 'LATO';
  font-weight: 300;
  width: fit-content;
  padding: 10px 15px;
  border-bottom: solid 1px $primary;
  margin-bottom: 50px;

  p {
    margin-bottom: 0;
  }
}
</style>
