<template>
  <v-app id="app-portfolio">
    <Navbar />
    <div class="main-container">
      <Home />
      <About />
      <Accomplishments />
    <Projects />
    </div>
    <Career />
    <Contact />
  </v-app>
</template>

<script>
import Navbar from './components/organism/Navbar';
import Home from './components/organism/Home';
import About from './components/organism/About';
import Accomplishments from './components/organism/Accomplishments';
import Career from './components/organism/Career';
import Contact from './components/organism/Contact';
import Projects from './components/organism/Projects'

export default {
  name: 'App',
  components: {
    Navbar,
    Home,
    About,
    Accomplishments,
    Career,
    Contact,
    Projects,
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/global';
#app-portfolio {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  background-image: url(assets/images/vetor-intro.png);
  background-repeat: no-repeat;
  background-position: 0% 725px;
  background-size: 100%;
  width: 100%;

  .main-container {
    display: flex;
    flex-direction: column;
    margin: 70px auto;
    justify-content: space-between;
    width: 80%;
    max-width: 1450px;
    padding: 0;
  }
}
</style>
