<template>
  <v-container class="carousel">
    <v-carousel
      height="600"
      cycle
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        ><v-container fill-height fluid pa-0 ma-0>
          <v-layout fill-height justify-end align-start pt-6 mb-2>
            <v-flex xs10 md6>
              <v-card color="rgb(30, 30, 30)" class="pa-2">
                <span
                  class="font-weight-light paragraph white--text"
                  v-text="item.text"
                >
                </span>
              </v-card>
            </v-flex> </v-layout></v-container
      ></v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  name: 'Carousel',
  data() {
    return {
      items: [
        {
          src: require('../../assets/images/lucas_doc88.jpg'),
          text: 'Apresentação para empresa sobre Processos - DOC88 (11/2020)',
        },
        {
          src: require('../../assets/images/lucas_engie.jpg'),
          text: 'Apresentação no estande da empresa, evento AGRISHOW - ENGIE (04/2019)',
        },
        {
          src: require('../../assets/images/lucas_vivo.jpg'),
          text: 'Apresentação do Lucas Amadeu (Head Mkt Vivo) - JK IGUATEMI (06/2019)',
        },
        {
          src: require('../../assets/images/lucas_record.jpg'),
          text: 'Visita no estúdio de gravação da RecordTV - ENGIE (06/2019)',
        },
        {
          src: require('../../assets/images/lucas_powerbi.jpeg'),
          text: 'Apresentação sobre BI e Data Science - Anhembi Morumbi Mooca (10/2019)',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
