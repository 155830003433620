<template>
  <section class="projects_companies">
    <Title text="PROJETOS" />
    <span class="intros"
      >Alguns dos projetos que contribui ao longo destes anos.</span
    >
    <div class="projects">
      <Project
        icon="logo-powerview.jpg"
        title="Powerview by Comerc"
        description="O Powerview é uma plataforma Web e Aplicativo de soluções em energia"
        exp="Contribui com estes projetos (Plataforma Web e App) como Business Partner e Desenvolvedor"
      />
      <Project
        icon="yam.jpg"
        title="YAM"
        description="YAM é uma e-commerce de saúde e bem estar, com foco em cursos on-line"
        exp="Atuei neste projeto como Product Owner"
      />
      <Project
        icon="follow_energy.jpg"
        title="Follow Energy by Engie"
        description="O Follow Energy é um sistema de telemetria com automação on-line"
        exp="Atuei na área comercial, vendendo e auxiliando a equipe técnica a implementar os projetos"
      />
    </div>
  </section>
</template>

<script>
import Project from '../molecules/Project';
import Title from '../atoms/Title';
export default {
  name: 'Projects',
  components: {
    Title,
    Project,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';
.projects_companies {
  width: 100%;

  .intros {
    margin: 100px 0 !important;
    width: 100%;
    font-size: 1.3rem;
    color: $white;
    font-weight: 200;
  }

  .projects {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
    align-items: baseline;
  }
}
@include for-size(phone-only) {
  .projects_companies {
    .projects {
      display: flex;
      width: 100%;
    }
  }
}
</style>
