<template>
  <div class="transition-icon">
    <v-icon large color="grey darken-2">
      {{ iconName }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'transition-icon',
  props: ['icon-name'],
};
</script>

<style lang="scss" scoped>
.transition-icon {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 50px;
}
</style>
