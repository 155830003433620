<template>
  <section id="career" class="careers">
    <!-- <Title text="Carreira" /> -->
    <h1 class="highlighted-title">Carreira</h1>
    <div class="careers-group">
      <CareerCard
        title="Doc88"
        body="IT Business Partner"
        time="Mai 2021 - o momento"
        :current=true
      />
      <TransitionIcon class="transition-icon" icon-name="mdi-dots-horizontal" />
      <CareerCard
        title="Doc88"
        body="Business Analyst"
        time="Nov 2020 - Mai 2021"
        current=false
      />
      <TransitionIcon class="transition-icon" icon-name="mdi-dots-horizontal" />
      <CareerCard
        title="Doc88"
        body="Desenvolvedor Front-end"
        time="Set 2020 - Nov 2020"
        current=false
      />
      <TransitionIcon class="transition-icon" icon-name="mdi-dots-horizontal" />
      <CareerCard
        title="Doc88"
        body="Estágio de Programação Front-end"
        time="Jul 2020 - Set 2020"
        current=false
      />
      <TransitionIcon class="transition-icon" icon-name="mdi-chevron-left" />
      <CareerCard
        title="Comerc"
        body="Estágio Comercial Engenharia Elétrica"
        time="Jan 2020 - Jul 2020"
        current=false
      />
      <TransitionIcon class="transition-icon" icon-name="mdi-chevron-left" />
      <CareerCard
        title="Engie"
        body="Estágio Comercial Engenharia Elétrica"
        time="Jun 2018 - Jan 2020"
        current=false
      />
      <TransitionIcon class="transition-icon" icon-name="mdi-chevron-left" />
      <CareerCard
        title="Solve"
        body="Programador Assistente IoT - C++ e Arduino"
        time="Ago 2016 - Mai 2018"
        current=false
      />
    </div>
  </section>
</template>

<script>
import CareerCard from '../molecules/CareerCard';
import TransitionIcon from '../atoms/TransitionIcon';

export default {
  name: 'Carreira',
  components: { CareerCard, TransitionIcon },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.careers {
  width: 100%;
  background-color: $primary;

  .highlighted-title {
    font-size: 2.5rem;
    text-align: center;
    color: $white;
    font-family: 'Rubik';
    text-transform: uppercase;
    margin: 75px auto 50px;
    font-weight: bold;
  }

  .careers-group {
    flex-wrap: wrap;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    justify-content: center;

    @include for-size(phone-only) {
      flex-direction: column;
      align-items: center;
      .transition-icon {
        display: none;
      }
    }
  }
}
</style>
