<template>
  <section id="home" class="home">
    <div class="left">
      <div class="main-titles">
        <h1>Design,</h1>
        <h1>Develop and</h1>
        <h1>Measure it<span class="yellow-dot">.</span></h1>
      </div>

      <div class="mobile_pic">
        <img src="../../assets/images/foto_mobile.png" alt="Foto Lucas Zarza" />
      </div>

      <h2 class="intro">
        Muito prazer, me chamo <strong>Lucas Zarza</strong> e atualmente
        trabalho como <strong>IT Business Partner</strong> em uma empresa de
        tecnologia, sou interessado em desenvolvimento de software, UX/UI, dados
        e gestão de projetos.
      </h2>

      <button
        class="cta_button"
        @click="
          track_cta_button();
          redirect();
        "
      >
        ENTRE EM CONTATO
      </button>

      <p class="hide-desktop">Links</p>

      <div class="external-links">
        <a href="https://www.linkedin.com/in/lucaszarza/" target="_blank"
          ><v-icon class="icons_links" medium color="grey darken-2">
            mdi-linkedin </v-icon
          >LinkedIn</a
        >
        <a href="https://www.instagram.com/lucasszarza/" target="_blank"
          ><v-icon class="icons_links" medium color="grey darken-2">
            mdi-instagram </v-icon
          >Instagram</a
        >
        <a href="https://github.com/lucaszarza" target="_blank"
          ><v-icon class="icons_links" medium color="grey darken-2">
            mdi-github </v-icon
          >Github</a
        >
        <a href="https://www.hackerrank.com/lucas_lsz" target="_blank"
          ><v-icon class="icons_links" medium color="grey darken-2">
            mdi-laptop </v-icon
          >Hackerrank</a
        >
      </div>
    </div>
    <v-flex class="right">
      <img src="../../assets/images/me_black_n_white.png" alt="Lucas Zarza" />
    </v-flex>
  </section>
</template>

<script>
export default {
  name: 'Home',
  props: {},
  methods: {
    track_cta_button() {
      this.$gtag.event('cta-contact-button', {
        event_category: 'cta-button',
        event_label: 'Botão de entrar em contato Clicado',
        value: 1,
      });
    },
    redirect() {
      console.log('redirecionando para uma nova página');
      window.open('https://mywhats.net/lucaspersonalnumber', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';
.home {
  width: 100%;
  display: flex;

  .left {
    z-index: 15;
    .main-titles {
      font-size: 85px;
      font-family: 'Rubik';
      font-weight: 800;
      margin-top: 100px;
      line-height: 8rem;
      color: $secondary;

      h1:first-child {
        color: $primary;
      }

      h1:last-child {
        color: $third;

        .yellow-dot {
          color: $primary;
        }
      }
    }

    .mobile_pic {
      display: none;
    }

    .intro {
      font-family: 'Montserrat';
      font-weight: 400;
      color: $white;
      width: 50%;
      min-width: 600px;
      margin: 20px 0;
    }

    .cta_button {
      z-index: 100;
      margin: 5px 0;
      font-family: 'LATO';
      padding: 15px 30px;
      border-radius: 3px;
      border-bottom: solid 1px $darker-grey;
      font-weight: 300;
      font-size: 1.2rem;
      background-color: $primary;
      transition: all 0.2s;
      color: $white;
      height: 60px;
    }

    .cta_button:hover {
      background: $navbar;
    }

    .hide-desktop {
      display: none;
    }

    .external-links {
      font-family: 'Rubik';
      font-weight: 300;
      font-size: 1.1rem;
      margin: 200px 0 50px;
      z-index: 30;
      a {
        text-decoration: none;
        color: $white;
        margin-right: 10px;
        transition: all 0.2s;
        padding: 5px 10px;
        border-radius: 2px;
        z-index: 30;

        .icons_links {
          margin-right: 5px;
        }
      }

      > a:hover {
        color: $primary;
        background-color: $darker-grey;
      }
    }
  }
  .right {
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 5 img {
      z-index: 5;
      pointer-events: none;
    }
  }
}

@include for-size(phone-only) {
  .home {
    flex-direction: column;

    .left {
      z-index: 15;
      .main-titles {
        font-size: 2.5rem;
        text-align: center;
        margin: 50px 0;
        line-height: 4rem;
      }

      .mobile_pic {
        display: flex;
        width: 100%;

        img {
          width: 300px;
          height: 300px;
          margin: 0 auto;
        }
      }

      .intro {
        font-size: 1.2rem;
        text-align: center;
        min-width: 0;
        width: 100%;
      }

      button {
        width: 100%;
        .cta_button {
          width: 100%;
          border-radius: 3px;
        }
      }

      .hide-desktop {
        display: block;
        color: $primary;
        font-size: 1rem;
        margin: 30px 0 10px auto;
        text-align: center;
      }

      .external-links {
        font-size: 1.1rem;
        margin: 10px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem 1rem;
        flex-direction: column;
        text-align: center;

        > a {
          margin: 5px auto;
          color: $white;
          font-size: 1rem;
          width: 145px;
          padding: 10px;
          border-radius: 5px;
          background-color: $darker-grey;

          .icons_links {
            margin-right: 2px;
          }
        }
      }
    }
    .right {
      display: none;
    }
  }
}
</style>
