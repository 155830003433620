import Vue from 'vue';
import App from './App.vue';
import store from './store';
import vuetify from '@/plugins/vuetify';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
  config: { id: 'G-C9RWV2X6JM' },
});

Vue.config.productionTip = false;

Vue.use(VueRouter);

new Vue({
  store,
  vuetify,
  axios,
  render: (h) => h(App),
}).$mount('#app');
