<template>
  <div v-if="current === true" class="career-unitary-actual">
    <p class="title-career-actual">{{ title }}</p>
    <span class="body-career-actual">{{ body }}</span>
    <p class="time-career-actual">{{ time }}</p>
    <span class="current">trabalho atual</span>
  </div>
  <div v-else class="career-unitary">
    <p class="title-career">{{ title }}</p>
    <span class="body-career">{{ body }}</span>
    <p class="time-career">{{ time }}</p>
  </div>
</template>

<script>
export default {
  name: 'Career-Card',
  props: ['title', 'body', 'time','current'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.career-unitary {
  text-align: center;
  display: flex;
  width: 250px;
  flex-direction: column;
  color: $darker-grey;
  margin-bottom: 20px; 

  .title-career {
    font-family: 'LATO';
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }
  .body-career {
    font-family: 'MONTSERRAT';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: bolder;
    // -webkit-box-pack: center;
    // -webkit-text-stroke: 1px $darker-grey;
    color: $darker-grey;
    transition: all .2s;
  }
}

.career-unitary:hover {
  
  .body-career {
    transform: scale(1.02)
  }
}

.career-unitary-actual {
  text-align: center;
  display: flex;
  width: 250px;
  flex-direction: column;
  margin-bottom: 20px;

  .current {
    font-family: 'MONTSERRAT';
    background-color: $darker-grey;
    color: $white;
    font-weight: bold;
    width: fit-content;
    margin: 0 auto;
    font-size: 0.7rem;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .title-career-actual {
    font-family: 'LATO';
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    color: $darker-grey; 
  }
  .body-career-actual {
    font-family: 'MONTSERRAT';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.5rem;
    font-weight: bolder;
    // -webkit-box-pack: center;
    // -webkit-text-stroke: 1px $darker-grey;
    color: $darker-grey;
    transition: all .2s;
  }

  .time-career-actual {
    color: $darker-grey;
  }
}
</style>
