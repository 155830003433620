<template>
  <div class="project">
    <img :src="require(`../../assets/images/${icon}`)" alt="" class="icon" />
    <p class="title_badge">{{ title }}</p>
    <p class="exp">
      {{ description }}
    </p>
    <v-btn icon dark @click="show = !show">
      <v-icon dark>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
    <v-expand-transition>
      <div v-show="show">
        <v-card-text class="exp">
          {{ exp }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'Project',
  props: ['title', 'icon', 'description', 'exp'],
  data: () => ({
    show: false,
  }),
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/global';

.project {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px;
  border-radius: 3px;
  text-align: center;
  // border: solid 2px $navbar;

  .title_badge {
    font-size: 1.4rem;
    font-weight: 500;
    font-style: 'Montserrat';
    color: $primary;
    margin: 15px 0;
  }

  .exp {
    font-family: 'LATO';
    border-top: 50px;
    font-size: 1.1rem;
    font-weight: 300;
    color: $secondary;
    letter-spacing: 0.1em;
  }

  p:last-child {
    font-family: 'Montserrat';
  }

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    filter: grayscale(0%);
    transition: all 0.2s;
  }

  img:hover {
    filter: grayscale(50%);
  }
}

@include for-size(tablet-portrait-up) {
  .project {
    align-items: center;
    margin: 0 auto;
  }
}
</style>
